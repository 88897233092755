@import "mixins"
.phoneInput
  position: relative

  --width: 100px

  & :global
    .intl-tel-input-label
      color: var(--common_field_labelColor)
      text-transform: capitalize
      font-size: var(--base_smallFontSize)
      line-height: calc(var(--base_smallFontSize) + 1px)
      @include flex

    .intl-tel-input
      .phone-widget
        border: 1px solid transparent
        background: var(--field_bg)
        font-size: 12px
        color: var(--common_field_color)
        padding: 6px 8px !important
        border-radius: var(--base_borderRadius)
        margin-left: var(--width) !important
        width: calc(100% - var(--width))
        &::placeholder
          color: var(--common_field_color)
          opacity: 0.5

      &.allow-dropdown.separate-dial-code.selected-flag
        background: var(--field_bg)

      .flag-container
        padding: 0
        height: 26px

        .arrow
          @include arrowIcon
          background-color: var(--icon_base_fill)
          opacity: 1

          &.up
            transform: rotate(180deg)

          &:after
            content: none !important

        &:hover .selected-flag
          background: var(--field_bg)

        .selected-flag
          @include flex
          border-radius: var(--base_borderRadius)
          padding: 0 8px
          width: 88px !important
          background: var(--field_bg)

          .selected-dial-code
            padding-left: 4px
            margin-right: auto
            pointer-events: none

      .country-list
        width: calc(100% + 2px)
        left: 0
        top: 26px
        border-radius: var(--base_borderRadius)
        z-index: 10
        background: var(--phoneInput_countryListBg)
        border-color: var(--phoneInput_countryListItemBorderBottom)

        .country
          @include ellipsis
          padding: 5px 10px
          color: var(--common_field_color)

          &.highlight
            background-color: rgba(0, 0, 0, 0.06)

          &:not(:first-of-type)
            border-top: 1px solid var(--phoneInput_countryListItemBorderBottom)
            &:not(:last-of-type)
              border-bottom: 1px solid var(--phoneInput_countryListItemBorderTop)
          .dial-code
            color: var(--common_field_color)
            opacity: 0.5

        .divider
          padding: 0
          margin-bottom: 1px
          box-shadow: 0 1px var(--phoneInput_countryListItemBorderTop)
          border-top: 1px solid var(--phoneInput_countryListItemBorderTop)
          border-bottom-color: var(--phoneInput_countryListItemBorderBottom)

      ::-webkit-scrollbar
        width: 8px

      ::-webkit-scrollbar-thumb
        background: var(--body_bg)
        border: 2px solid var(--phoneInput_countryListBg)
        border-radius: 10px

  &.oneCountry
    --width: 76px
    :global(.flag-container .selected-flag)
      cursor: default
      width: 68px !important
    :global(.arrow)
      display: none

.error
  composes: error from "components/base/input-field/input-field.module.sass"

.fieldError
  composes: fieldError from "components/base/input-field/input-field.module.sass"
